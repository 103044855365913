import React from 'react';
import { connect } from 'react-redux';
import '../../styles/play_styles.scss';

class NavBar extends React.Component {

    state = {
    };

    render() {
        return (
            <nav className={`navbar navbar-light ${!!this.props.class ? this.props.class : 'play_Navbar'}`}>
                <a className="navbar-brand" href={`/${process.env.REACT_APP_HOME}`}>
                    <img src={this.props.image} alt="" className='play_Icon' />
                </a>
            </nav>
        );
    }
}

const mapStateToProps = state => {
    return {

    };
};

export default connect(mapStateToProps, {})(NavBar);


