export const ValidateMinLength = (text, min, message) => {
    if (text && text !== null && text.length >= min)
        return null
    return message
}

export const RenderSelectInputCheck = (nameInput, valueInput, placeholder, options, that) => {
    return <div className="container">
        <p className="bh_header_choice_input" >{placeholder}</p>
        <div className="row" id={nameInput}>
            <div className="col">
                <div className="form-check round-checkbox">
                    <input className="form-check-input bh_check_circle" onClick={() => { that.handleChangeSelectInputCheck(valueInput, options[0], nameInput); }} type="radio" name="options" id="radio1" />
                    <label className="form-check-label" htmlFor="radio1">
                        {options[0]}
                    </label>
                </div>
            </div>
            <div className="col">
                <div className="form-check round-checkbox">
                    <input className="form-check-input bh_check_circle" onClick={() => { that.handleChangeSelectInputCheck(valueInput, options[1], nameInput); }} type="radio" name="options" id="radio2" />
                    <label className="form-check-label" htmlFor="radio2">
                        {options[1]}
                    </label>
                </div>
            </div>
        </div>
        <div className="invalid-feedback play_invalid play_captcha_warning bh_warning_choice">
            <span className="material-icons play_icon">warning</span>
            {that.state[nameInput]}
        </div>
    </div>
}

export const RenderSelectInput = (nameInput, valueInput, placeholder, options, that) => {
    return <div className="col-md-12 form-group ">
        <div className="container-inputs">
            <div className="placeholder-333333 input_padding_all">
                <select asp-for={nameInput} id={nameInput} className="form-control  play_Input " value={that.state[valueInput]} placeholder={placeholder} onChange={(e) => { that.state[valueInput] = e.target.value; that.setState({ ...that.state }); }}>
                    <option value={""}>{""}</option>
                    {options.map((o) => <option key={o} value={o}>{o}</option>)}
                </select>
                <div className="invalid-feedback play_invalid play_captcha_warning">
                    <span className="material-icons play_icon">warning</span>
                    {that.state[nameInput]}
                </div>
            </div>
        </div>
    </div>
}

export const RenderInput = (nameInput, valueInput, placeholder, maxLength, type, that) => {
    return <div className="col-md-12 form-group bh_form_size ">
        <div className="container-inputs">
            <div className="placeholder-333333 input_padding_all">
                <input asp-for={nameInput} id={nameInput} placeholder={placeholder} type="text" className="form-control  play_Input " value={that.state[valueInput]} onChange={(e) => that.handleChangeInput(e, valueInput, maxLength, type, nameInput)} />
                <div className="invalid-feedback play_invalid play_captcha_warning" style={{ display: !!that.state[nameInput] && !!that.state[valueInput] ? 'flex' : 'none' }}>
                    <span className="material-icons play_icon">warning</span>
                    {that.state[nameInput]}
                </div>
            </div>
        </div>
    </div>
}


export const RenderParticipatePopup = () => {

    return (
        <div className="row">
            <div className="col-md-12 form-group">
                <div className="container-inputs">
                    <div className="placeholder-333333">

                        <button type="submit" id="participar_subscribe" onKeyPress={(e) => {
                            if (e.key === 13)
                                e.preventDefault()
                        }} onClick="" className="btn participate-button" value="Participar">
                            Participar
                            <i className=""></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}


export const RenderCheckBox = (id, valueInput, text, that, ga_action) => {

    return <div className="col-md-12 form-group second_segment">
        <div className="container-inputs">
            <div className="input-group ">
                <label className="wrapper-input checkbox">
                    <div className="form-check">
                        <label className="custom-checkbox">
                            <input className="form-check-input" checked={that.state[valueInput]} onChange={(e) => {
                                window.gtag('event', 'Pagina_registro', { 'eventCategory': 'Checks_participacion', 'eventLabel': ga_action });
                                that.handleChangeInputChecked(e, valueInput, id);
                            }} type="checkbox" id={id} />
                            <span className="check play_check"></span>
                            {text}
                            <div className="invalid-feedback play_invalid play_captcha_warning">
                                <span className="material-icons play_icon">warning</span>
                                {that.state[id]}
                            </div>
                        </label>
                    </div>
                </label>
            </div>
        </div>
    </div>
}