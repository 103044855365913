import Home from "./components/Promotion/Home";
// import PlayParticipate from "./components/Promotion/play_Participate";
import PlayParticipated from "./components/Promotion/play_Participated";
import PlayCelebrate from "./components/Promotion/play_Celebrate";
import RegisterParticipation from "./components/Promotion/register_participation";
import PlayThankYou from './components/Promotion/play_ThankYou';
import Error from './components/Promotion/play_Error';

const AppRoutes = [
  {
    index: true,
    element: <Home />
  },
  {
    path: `/${process.env.REACT_APP_HOME}`,
    element: <RegisterParticipation />
  },
  // {
  //   path: `/${process.env.REACT_APP_HOME}`,
  //   element: <PlayParticipate />
  // },
  {
    path: `/${process.env.REACT_APP_HOME}/hasparticipado`,
    element: <PlayParticipated />
  },
  {
    path: `/${process.env.REACT_APP_HOME}/hasganado`,
    element: <PlayCelebrate />
  },
  {
    path: `/${process.env.REACT_APP_HOME}/gracias`,
    element: <PlayThankYou />
  },
  {
    path: `/${process.env.REACT_APP_HOME}/error`,
    element: <Error />
  },
  {
    path: '*',
    element: <Home />
  }
];

export default AppRoutes;