import React from 'react';
import { connect } from 'react-redux';
import config from '../../configuration.json';
import { Navigate } from 'react-router-dom';
import Cookies from 'universal-cookie';
import axios from 'axios';
import * as bootstrap from 'bootstrap';
import HeaderImage from './play_HeaderImage';
import Modal from '../../modal/default';
import Navbar from './play_Navbar';
import { RenderInput } from '../../utils/renderInput';
import { validateCelebrateControls, removeInvalidValidClass } from './utils/handlerMessage';
import { ParticipationRegisterStatus } from './utils/ParticipationRegisterStatus';
import { setResultObject, setPromoState } from '../../actions';
import MyModal from '../../modal/newModal';

class PlayCelebrate extends React.Component {

    state = {
        page: "Telepizza - Tu premio",
        useNavigation: null,
        valueEmail: '',
        valueName: '',
        valueLastName: '',
        valueNif: '',
        registeredEmail: '',
        errorElement: [],
        renderModal: null,
        contentModal: null,
        tiltleModal: null,
        loader: null
    };

    componentDidMount() {

        document.title = this.state.page
        this.cookies = new Cookies();
        const canContinue = this.cookies.get("XSRF-TOKEN") 
            && this.props.promoState == ParticipationRegisterStatus.ParticipationRegisteredWinner
            && this.props.result
            && this.props.result.winningMomentResult;
        if (canContinue) {
            this.setState({ tokenSession: this.cookies.get("XSRF-TOKEN") });
            const registeredEmail = this.props.result.participateRequest.email;
            this.setState({ valueEmail: registeredEmail });
            this.setState({ registeredEmail: registeredEmail });
            let errElments = [];
            config.PlayAndPizza.Views.Celebrate.ErrorElements.forEach(element =>
                errElments[element] = false
            );
            this.setState({ errorElement: errElments })
        }
        else {
            this.setState({ useNavigation: <Navigate to={`/${process.env.REACT_APP_HOME}`} replace={true} /> })
        }
    }

    disableSubmitButton() {
        let bt = document.getElementById("participar_register_winner_data")
        bt.setAttribute("disabled", true)
    }
    
    enableSubmitButton() {
        let bt = document.getElementById("participar_register_winner_data");
        bt.removeAttribute("disabled");
    }

    handleChangeInput(e, valueInput, maxLength, type, id) {
        let value = e.target.value.slice(0, maxLength);
        switch (type) {
            case "numeric":
                value = value.replace(/\D/g, '');
                break;
            default:
                value = value;
                break;
        }
        removeInvalidValidClass(id);
        this.state[valueInput] = value;
        this.state[id] = null;
        this.setState({ ...this.state });
        this.enableSubmitButton();
        return value
    }

    registerParticipationData(e) {

        let formCheck = validateCelebrateControls(config, this);
        if (formCheck === null){
            this.SubmitDataRegister();
        }
        else {
            this.disableSubmitButton();
        }
        e.preventDefault();
        e.stopPropagation();
    }

    async SubmitDataRegister() {
        let formData = new FormData();
        formData.append("RegisteredEmail", this.state.registeredEmail);
        formData.append("Email", this.state.valueEmail);
        formData.append("Name", this.state.valueName);
        formData.append("LastName", this.state.valueLastName)
        formData.append("Nif", this.state.valueNif);
        this.disableSubmitButton();
        this.showLoader();
        axios.post('winner/data', formData, {
            withCredentials: true,
            headers: { Accept: "multipart/form-data", 'X-XSRF-TOKEN': this.cookies.get("XSRF-TOKEN") }
        }).then(response => {
            this.hideLoader();
            this.enableSubmitButton();
            const registerResult = response.data;
            if (registerResult.result) {
                this.nextStep(registerResult)
            }
            else{
                this.setState({renderModal: <MyModal title="Ups" content={registerResult.errorMessage} buttonText="Entendido" onExitedCallBack={() => this.setState({renderModal: null})}/>});
            }
        }).catch((e) => { console.log(e); this.enableSubmitButton(); this.hideLoader(); this.handlerError() });
    }

    nextStep(registerResult) {
        const that = this;
        setTimeout(() => {
            that.redirectToThankyou(registerResult);
        }, 500);
    }

    handlerError() {
        this.setState({renderModal: <MyModal title="Error" content="Ha ocurrido algún error inesperado. Vuelve a intentarlo más tarde"/>});
    }

    redirectToThankyou(registerResult){
        this.props.setPromoState(ParticipationRegisterStatus.ParticipationDataRegistered);
        this.props.setResultObject(registerResult);
        this.setState({ useNavigation: <Navigate to={`/${process.env.REACT_APP_HOME}/gracias`} replace={true} /> });
    }

    showLoader = () => {
        this.setState({ loader: <MyModal title="" content="Registrando tus datos" buttonText="" animate={false}/>});
    }

    hideLoader = () => {
        this.setState({ loader: null });
    }

    render() {
        let image = null;
        let tiltle = null;
        let text = null;
        if (this.props.result && this.props.promoState === ParticipationRegisterStatus.ParticipationRegisteredWinner) {
            image = this.props.result.winningMomentResult.imgWin ? this.props.result.winningMomentResult.imgWin : config.PlayAndPizza.Celebrate.ImgWin;
            tiltle = this.props.result.winningMomentResult.messageTitleWin ? this.props.result.winningMomentResult.messageTitleWin : config.PlayAndPizza.Celebrate.MessageTitleWin;
            text = this.props.result.winningMomentResult.messageTextWin ? this.props.result.winningMomentResult.messageTextWin : "";
        }

        return (
            <div className='play_background' id='play_background'>
                {this.state.useNavigation}
                <Navbar image={config.PlayAndPizza.HeaderIconlUrl} />
                <HeaderImage image={image}/>
                {this.state.loader}
                <div className="mx-auto">
                    <form ref="submitDataForm" className='needs-validation' noValidate id="register-data-form">
                        <div className="col-12">
                            <div className="row mx-auto">
                                <div className=" col-12">
                                    <h3 className='play_text_Intro'>{tiltle}</h3>
                                </div>
                                <div className="col-12 play_tiltle_video">
                                    {config.PlayAndPizza.Intro.Detail ? <p>{text}</p> : null}
                                </div>
                            </div>
                            <div className="row ">
                                <div className=" col-12 mx-auto  form-group play_group">
                                    {RenderInput("Name", "valueName", "Nombre", 50, "", this)}
                                    {RenderInput("LastName", "valueLastName", "Apellidos", 100, "", this)}
                                    {RenderInput("Nif", "valueNif", "DNI", 100, "", this)}
                                    {RenderInput("Email", "valueEmail", "Email", 100, "email", this)}
                                    <div className='row ' >
                                        <div className='col text-center'>
                                            <button onClick={(e) => {
                                                window.gtag('event', 'EnviarDatos', { 'eventCategory': 'Participacion', 'eventLabel': 'Boton_Continuar' });
                                                this.registerParticipationData(e)
                                            }} id="participar_register_winner_data" className="btn play_button">{config.PlayAndPizza.Celebrate.Action}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {this.state.renderModal}
                    </form>
                </div>
                <Modal id={"Subscribe_Modal_noti"} content={this.state.contentModal} tiltle={this.state.tiltleModal} />
            </div >
        );
    }
}

const mapStateToProps = state => {
    return {
        result: state.pageReducer.result,
        promoState: state.pageReducer.promoState
    };
};

export default connect(mapStateToProps, { setResultObject, setPromoState })(PlayCelebrate);