import {
  RESULT,
  STATE,
  STATE_SURVEY,
  QUESTION,
  EXPAND,
  PROMO_STATE
} from "../actions/types";

const INTIAL_STATE = {
  result: null,
  promoState: null,
  state: null,
  state_survey: null,
  expand: false,
  question: []
};
const pageReducer = (state = INTIAL_STATE, action) => {
  switch (action.type) {
    case RESULT:
      return { ...state, result: action.payload };
    case STATE:
      return { ...state, state: action.payload };
    case STATE_SURVEY:
      return { ...state, state_survey: action.payload };
    case QUESTION:
      return { ...state, question: action.payload };
    case EXPAND:
      return { ...state, expand: action.payload };
    case PROMO_STATE:
      return { ...state, promoState: action.payload};
    default:
      return state;
  }
};
export default pageReducer;