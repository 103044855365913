import React from 'react';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';
import config from '../../configuration.json';
import Cookies from 'universal-cookie';
import Navbar from './play_Navbar';
import { setResultObject, setPromoState } from '../../actions';
import { ParticipationRegisterStatus } from './utils/ParticipationRegisterStatus';

class PlayParticipated extends React.Component {

    state = {
        useNavigation: null,
        page: "Telepizza - Has participado"
    };

    componentDidMount() {
        document.title = this.state.page;
        this.cookies = new Cookies();
        if (this.props.promoState != ParticipationRegisterStatus.ParticipationRegisteredNotWinner){
            this.setState({ useNavigation: <Navigate to={`/${process.env.REACT_APP_HOME}`} replace={true} /> })
        }
    }

    redirectHome = () => {
        this.props.setPromoState(null);
        this.props.setResultObject(null);
        this.setState({ useNavigation: <Navigate to={`/${process.env.REACT_APP_HOME}`} replace={true} /> })
    }

    onActionClick = (e) => {
        const that = this;
        
        window.gtag('event', 'GotoHome', { 'eventCategory': 'HasParticipated', 'eventLabel': 'Boton_OK' });

        setTimeout(() => {
            that.redirectHome();
        }, 500);

        e.preventDefault();
        e.stopPropagation();
    }

    render() {
        return (
            <div className='play_background' id='play_background'>
                {this.state.useNavigation}
                <Navbar image={config.PlayAndPizza.HeaderIconlUrl} />
                <div className="mx-auto">
                    <div className="col-12">
                        <div className="row mx-auto">
                            <div className=" col-12">
                                <h3 className='play_text_Intro'>{config.PlayAndPizza.HasParticipated.Title}</h3>
                            </div>
                            <div className="col-12 play_tiltle_video">
                                {config.PlayAndPizza.HasParticipated.Detail ? <p>{config.PlayAndPizza.HasParticipated.Detail}</p> : null}
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row ' >
                    <div className='col text-center'>
                        <button onClick={this.onActionClick} id="participar_subscribe" className="btn play_button">{config.PlayAndPizza.HasParticipated.Action}</button>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        result: state.pageReducer.result,
        promoState: state.pageReducer.promoState
    };
};

export default connect(mapStateToProps, { setResultObject, setPromoState })(PlayParticipated);