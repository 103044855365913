import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";

function MyModal(props) {
  
    const [show, setShow] = useState(true);

    const handleClose = () => setShow(false);

    const {animation = false, onExitedCallBack = () => {}} = props;

    const renderTitle = () => {
        if (!!props.title) {
            return (
                <Modal.Header>
                    <Modal.Title>{props.title}</Modal.Title>
                </Modal.Header>
            );
        }

        return (<></>);
    }

    const renderFooter = () => {
        if (!!props.buttonText) {
            return (
                <Modal.Footer>
                    <Button variant="primary" onClick={handleClose}>
                        {props.buttonText}
                    </Button>
                </Modal.Footer>
            );
        }

        return (<></>);
    }

    return (
    <>
        <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} animation={animation} centered={true} onExited={onExitedCallBack}>
            {renderTitle()}
            <Modal.Body>{props.content}</Modal.Body>
            {renderFooter()}
        </Modal>
    </>
    );
}

export default MyModal;
