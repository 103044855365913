import React from 'react';
import { connect } from 'react-redux';
import '../../styles/miercoyes.scss';
import loaderImage from '../../img/vf_miercoyes_animacion_carga_20102021_v3.gif';

class Loading extends React.Component {

    state = {
    };

    componentDidMount() {

    }

    render() {
        return (
            <div className={`loading ${this.props.show ? 'showElement ' : 'hiddenElement'} ${this.props.position ? 'modal_icon_default' : ''}`}>
                <div className="content">
                    <img src={loaderImage} />
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {

    };
};

export default connect(mapStateToProps, {})(Loading);


