import React from 'react';
import { connect } from 'react-redux';
import '../../styles/play_styles.scss';

class Video extends React.Component {

    state = {
        scale: false
    };

    componentDidMount() {

        window.addEventListener("resize", this.resize.bind(this));
        this.resize();
    }

    resize() {
        if (window.innerWidth <= 760) {
            this.setState({ scale: true });
        }
        else {
            this.setState({ scale: false });
        }
    }

    render() {
        return (
            <div className='row'>
                <div className='col-12'>
                    <div className='col-8 play_tiltle_video_ play_video_text mx-auto'>
                        <p>{this.props.text}</p>
                    </div>
                </div>
                <div>
                    <div className="container_video">
                        <iframe onClick={() => {
                            window.gtag('event', this.props.ct, { 'eventCategory': this.props.ct, 'eventLabel': this.props.video_ga });
                        }}
                            title='Telepizza player'
                            className="video video-player-iframe" id="player"
                            // src={`https://www.youtube.com/embed/huAkmgKCSMo?autoplay=0`}
                            src={this.props.videoSrc}
                            allowFullScreen >
                        </iframe>
                    </div>
                </div>
                {this.props.btn ? <div className='row justify-content-md-center play_row_left'>
                    <div className='col-3 offset-4 col-md-8'>
                        <button id="participar_subscribe" className="btn play_button_video" onClick={() => {
                            window.gtag('event', this.props.ct, { 'eventCategory': this.props.ct, 'eventLabel': this.props.video_ga });
                            this.props.redirect.scrollIntoView()
                        }}>{this.props.btn}</button>
                    </div>
                </div> : <></>}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {

    };
};

export default connect(mapStateToProps, {})(Video);


