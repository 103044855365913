import { Component } from 'react';
import { Route, Routes } from 'react-router-dom';
import AppRoutes from './AppRoutes';
import { Layout } from './components/Promotion/Layout';
import PlayCookies from './components/Promotion/play_Cookies';

export default class App extends Component {
  static displayName = App.name;

  componentDidMount() {
    this.loadPageRegister();
  }

  loadPageRegister() {
    const script = document.createElement('script');
    script.src = `https://www.googletagmanager.com/gtag/js?id=${window["Ga4Id"]}`
    document.head.appendChild(script);
    const script2 = document.createElement('script');
    script2.textContent = `   window.dataLayer = window.dataLayer || [];
    function gtag() { dataLayer.push(arguments); }
    gtag('js', new Date());
    var GA_TRACKING_ID_GA = window["Ga4Id"];
    const disablePropertyNameGA4 = 'ga-disable-${window["Ga4Id"]}';
    window[disablePropertyNameGA4] = true;
    window['GA_TRACKING_ID_GA'] = GA_TRACKING_ID_GA;
    gtag('config', GA_TRACKING_ID_GA, { 'anonymizeIp': true, send_page_view: false });`
    document.head.appendChild(script2);
    const script3 = document.createElement('script');
    script3.setAttribute('data-cookiecategory', 'analytics');
    script3.setAttribute('type', 'text/plain');
    script3.textContent = `window[disablePropertyNameGA4] = false; gtag('event', 'View', { page_title:document.title}); gtag('event', 'page_view', {page_title: document.title, page_location: window.location.href, page_path: window.location.pathname });`;
    document.head.appendChild(script3);
  }
  render() {
    return (
      <Layout>
        <PlayCookies />
        <Routes>
          {AppRoutes.map((route, index) => {
            const { element, ...rest } = route;
            return <Route key={index} {...rest} element={element} />;
          })}
        </Routes>
      </Layout>
    );
  }
}
