import React from 'react';
import { connect } from 'react-redux';
import '../../styles/play_styles.scss';
import config from '../../configuration.json';
import Video from './play_Video';
import { Navigate } from 'react-router-dom';
import { SetEventPage } from '../../utils/utilsGA';

class Error extends React.Component {

    state = {
        scale: false,
        body: null,
        urlanavegate: null,
        page: 'Telepizza - Error'
    };



    componentDidMount() {
        if (!!window.gtag)
            SetEventPage(this.state.page, window);
    }


    render() {
        return (
            <div className='bh_play_error' >
                {this.state.urlanavegate}
                <div className='icon'>
                    <img className='img' src={config.PlayAndPizza.HeaderIconlUrl} alt='tu gente es tu masa telepizza'></img>
                </div>
                <div>
                    <div className='div_title'>
                        <img className='img' src={config.PlayAndPizza.Survey.Error.CopyUrl} alt='telepizza cual es tu masa'></img>
                    </div>
                    <div className='div_text'>
                        <div className='text'>
                            Danos un minuto y sabrás qué tipo de masa formas con los tuyos.
                        </div>
                    </div>
                    <div className='div_gif'>
                        <img className='img' src={config.PlayAndPizza.Survey.Error.ImageUrl} alt='telepizza cual es tu masa'>
                        </img>
                    </div>
                    <div className='text_btn'>
                        <button className={`btn `} onClick={(e) => {
                            window.gtag('event', 'Reintentar', { 'eventCategory': 'Encuesta', 'eventLabel': 'Encuesta' });
                            this.setState({ urlanavegate: <Navigate to={`/${process.env.REACT_APP_HOME}`} replace={true} /> })
                        }}>
                            Reintentar
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {

    };
};

export default connect(mapStateToProps, {})(Error);


