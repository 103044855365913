import React from 'react';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';

class Home extends React.Component {

  state = {
    useNavigation: null
  };

  componentDidMount() {
    this.setState({ useNavigation: <Navigate to={`/${process.env.REACT_APP_HOME}`} replace={true} /> })
  }

  render() {
    return (
      <div className="home_promotion">
        {this.state.useNavigation}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {

  };
};

export default connect(mapStateToProps, {})(Home);


