import { ValidateMinLength } from '../../../utils/validateInputs';
import config from '../../../configuration.json';
const validNif_NIE = new RegExp("^[XYZ]\\d{7}[A-Z]$");
const validNif_NID = new RegExp('^\\d{8}[A-Z]$');
const validEmailRegExp = /^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
const validEmailGoogleSuspiciousRegExp = /^[a-z0-9!#$%&'*/=?^_`{|}~-]+[+]\d{9}@(gmail\.com|googlemail\.com)$/;

export function validateFunctions(id, that) {
    let mDefault = null

    switch (id) {
        case "PhoneNumber":
            mDefault = config.PlayAndPizza.Views.Subscribe.ErrorPhoneNumber;
            mDefault = that.state.valuePhoneNumber.length > 0 ? ValidateMinLength(that.state.valuePhoneNumber, 9, config.PlayAndPizza.Views.Subscribe.ErrorPhoneNumberFormat) : mDefault
            break;
        case "flexCheckDefault":
            mDefault = config.PlayAndPizza.Views.Subscribe.ErrorConditions;
            mDefault = that.state.checkBBLL === true ? null : mDefault
            break;
        case "Email":
            mDefault = validEmailRegExp.test(that.state.valueEmail) === true ? null : config.PlayAndPizza.Views.Subscribe.ErrorEmail
            mDefault = validEmailGoogleSuspiciousRegExp.test(that.state.valueEmail) === true ? config.PlayAndPizza.Views.Subscribe.ErrorEmailInvalid : mDefault
            break;
        case "Name":
            mDefault = !!that.state.valueName && that.state.valueName !== "" && that.state.valueName.length >= 2 ? null : config.PlayAndPizza.Views.Subscribe.ErrorName;
            mDefault = that.state.valueName ? mDefault : config.PlayAndPizza.Views.Subscribe.ErrorNameFormat;
            break;
        case "LastName":
            mDefault = !!that.state.valueLastName && that.state.valueLastName !== "" && that.state.valueLastName.length > 2 ? null : config.PlayAndPizza.Views.Subscribe.ErrorLastName
            mDefault = that.state.valueLastName ? mDefault : config.PlayAndPizza.Views.Subscribe.ErrorLastNameFormat;
            break;
        case "Nif":
            mDefault = validNif_NID.test(that.state.valueNif) === true || validNif_NIE.test(that.state.valueNif) === true ? null : config.PlayAndPizza.Views.Subscribe.ErrorDNIFormat
            mDefault = that.state.valueNif ? mDefault : config.PlayAndPizza.Views.Subscribe.ErrorDNI
            break;
        case "City":
            mDefault = !!that.state.valueCity && that.state.valueCity !== "" ? null : config.PlayAndPizza.Views.Subscribe.ErrorCity;
            mDefault = that.state.valueCity ? mDefault : config.PlayAndPizza.Views.Subscribe.ErrorCityFormat;
            break;
        case "Code":
            mDefault = config.PlayAndPizza.Views.Subscribe.ErrorCode;
            mDefault = that.state.valueCode.length > 0 ? null : mDefault
            break;
        case "PrizeType":
            mDefault = !!that.state.valuePrizeType && that.state.valuePrizeType !== "" ? null : config.PlayAndPizza.Views.Subscribe.ErrorPrizeType;
            mDefault = that.state.valuePrizeType ? mDefault : config.PlayAndPizza.Views.Subscribe.ErrorPrizeType;
            break;
        default:
            break;
    }

    var element = document.getElementById(id)
    if (mDefault !== null) {
        element.classList.add("is-invalid")
        element.classList.remove("is-valid")
    }
    else {
        element.classList.remove("is-invalid")
        element.classList.add("is-valid")
    }

    that.state[id] = mDefault
    that.setState({ ...that.state })
    return mDefault;
}

export function validateControls(config, that) {

    let formCheckAll = null;
    config.PlayAndPizza.Views.Subscribe.ErrorElements.forEach((element, ind) => {
        let formCheck = validateFunctions(element, that);
        if (formCheck !== null)
            formCheckAll = formCheck;
    }
    );
    return formCheckAll;
}

export function validateCelebrateControls(config, that) {

    let formCheckAll = null;
    config.PlayAndPizza.Views.Celebrate.ErrorElements.forEach((element, ind) => {
        let formCheck = validateFunctions(element, that);
        if (formCheck !== null)
            formCheckAll = formCheck;
    }
    );
    return formCheckAll;
}

export function removeInvalidValidClass(id) {
    var element = document.getElementById(id);
    element.classList.remove("is-valid");
    element.classList.remove("is-invalid");
}