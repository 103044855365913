import {
RESULT,
STATE,
STATE_SURVEY,
EXPAND,
PROMO_STATE
} from "./types";


export const setResultObject = (object) => async (dispatch) => {
  dispatch({ type: RESULT, payload: object });
};

export const setStatePage = (state) => async (dispatch) => {
  dispatch({ type: RESULT, payload: state });
};

export const setStateSurvey = (state) => async (dispatch) => {
  dispatch({ type: STATE_SURVEY, payload: state });
};

export const setExpand = (state) => async (dispatch) => {
  dispatch({ type: EXPAND, payload: state });
};

export const setPromoState = (promoState) => async (dispatch) => {
  dispatch({type: PROMO_STATE, payload: promoState});
}