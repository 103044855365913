export const ParticipationRegisterStatus = {
    NotInitiated: "NotInitiated",
    RegistrationFieldsErrors: "RegistrationFieldsErrors",
    EmailAlreadyParticipated: "EmailAlreadyParticipated",
    ParticipationRegisteredNotWinner: "ParticipationRegisteredNotWinner",
    ParticipationRegisteredWinner: "ParticipationRegisteredWinner",
    ParticipationDataRegistered: "ParticipationDataRegistered",
    InternalError: "InternalError",
    InvalidCode: "InvalidCode",
    AlreadyUsedCode: "AlreadyUsedCode",
}