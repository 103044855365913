import React from 'react';
import { connect } from 'react-redux';

class HeaderImage extends React.Component {

    render() {

        return (
            <div className='header-image-container row justify-content-center'>
                <img src={this.props.image} alt="" className='header-image' />
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {

    };
};

export default connect(mapStateToProps, {})(HeaderImage);