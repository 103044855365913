import React from 'react';
import { connect } from 'react-redux';

class Footer extends React.Component {

    state = {
        footerHeight: 0
    };

    footerRef = React.createRef();

    componentDidMount() {
        const footerHeight = this.footerRef.current.clientHeight;
        if (footerHeight !== this.state.footerHeight){
            this.setState({ footerHeight: footerHeight});
        }
    }

    render() {
        return (
            <>
            <div style={{height: `${this.state.footerHeight}px`}}></div>
            <footer id={this.props.footerName} ref={this.footerRef}>
                    <div className='row mx-auto  '>
                        <p className='footer-text text-center'>
                            Si tras 30 minutos no has recibido el email de confirmación ponte en contacto con nosotros <br/>
                            crm.es@fooddeliverybrands.com
                        </p>
                    </div>
            </footer>
            </>
        );
    }
}

const mapStateToProps = state => {
    return {

    };
};

export default connect(mapStateToProps, {})(Footer);


