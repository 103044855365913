import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';
import * as bootstrap from 'bootstrap';
import axios from 'axios';
import Cookies from 'universal-cookie';
import ReCAPTCHA from "react-google-recaptcha";
import '../../styles/play_styles.scss';
import '../../styles/promotion.scss';
import config from '../../configuration.json';
import Video from './play_Video';
import { setResultObject, setPromoState } from '../../actions';
import Modal from '../../modal/default';
import { RenderInput, RenderCheckBox } from '../../utils/renderInput';
import { validateControls, removeInvalidValidClass } from './utils/handlerMessage';
import Navbar from './play_Navbar';
import HeaderImage from './play_HeaderImage';
import { ParticipationRegisterStatus } from './utils/ParticipationRegisterStatus';
import MyModal from '../../modal/newModal';

// This comment is to avoid EsLint error for variable process
/* global process */

class RegisterParticipation extends React.Component {

    state = {
        useNavigation: null,
        loader: null,
        paramStatus: null,
        valueEmail: '',
        valueCode: '',
        valueName: '', 
        valueLastName: '',
        valueNif: '',
        checkPrivacyPolicy: false,
        checkBBLL: false, 
        act_retch: null,
        captcha: false,
        errorElement: [],
        register: null,
        contentModal: null,
        tiltleModal: null,
        elementCaptcha: null,
        repSubmit: 0,
        page: "Telepizza - Inicio",
        renderModal: null,
        gSiteKey: window.GSiteKey,
        participateButtonDisabled: false
    };

    redirections = {};
    errorHandlers = {};

    componentDidMount() {
        document.title = this.state.page
        let body = document.getElementById("body"); //Is this needed?
        body.classList.remove("play_body"); //Is this needed?
        let errElments = [];
        config.PlayAndPizza.Views.Subscribe.ErrorElements.forEach(element =>
            errElments[element] = false
        );
        this.setState({ errorElement: errElments })
        this.cookies = new Cookies();
        this.recaptchaRef = React.createRef();
        this.redirections[ParticipationRegisterStatus.ParticipationDataRegistered] = this.redirectThanks.bind(this);
        this.errorHandlers[ParticipationRegisterStatus.InvalidCode] = this.handleInvalidCodeError.bind(this);
        this.errorHandlers[ParticipationRegisterStatus.AlreadyUsedCode] = this.handleInvalidCodeError.bind(this);
        this.errorHandlers[ParticipationRegisterStatus.RegistrationFieldsErrors] = this.handleRegistrationFieldsErrors.bind(this);
    }

    disableSubmitButton() {
        this.setState({ participateButtonDisabled: true });
    }
    
    enableSubmitButton() {
        this.setState({ participateButtonDisabled: false });
    }

    handleChangeInput(e, valueInput, maxLength, type, id) {
        let value = e.target.value.slice(0, maxLength);
        switch (type) {
            case "numeric":
                value = value.replace(/\D/g, '');
                break;
            default:
                // value = value;
                break;
        }
        removeInvalidValidClass(id);
        const actualState = { ...this.state };
        actualState[valueInput] = value;
        actualState[id] = null;
        this.setState({ ...actualState});
        this.enableSubmitButton();
        return value
    }

    handleChangeInputChecked(e, valueInput, id) {
        removeInvalidValidClass(id);
        const actualState = { ...this.state };
        actualState[valueInput] = e.target.checked;
        actualState[id] = null;
        this.setState({ ...actualState })
        this.enableSubmitButton();
    }

    SubmmitReCaptcha= async () => {
        this.enableSubmitButton();
        let formData = new FormData();
        formData.append("TokenCaptcha", this.recaptchaRef.current.getValue());
        axios.post('register/recaptcha', formData, {
            withCredentials: true,
            headers: { Accept: "multipart/form-data", 'X-XSRF-TOKEN': this.cookies.get("XSRF-TOKEN") }
        }).then(response => { this.setState({ act_retch: response.data.tokenValidate }); this.setState({ captcha: response.data.tokenValidate }) }).catch(() => { this.handlerError() });
    }

    registerParticipate(e) {
        let formCheck = validateControls(config, this);
        if (this.state.captcha && formCheck === null) {
            this.SubmitPageRegister();
        }
        else {
            this.disableSubmitButton();
            this.setState({ act_retch: false });
        }
        e.preventDefault();
        e.stopPropagation();
    }
    
    async SubmitPageRegister() {
        let formData = new FormData();
        formData.append("Name", this.state.valueName);
        formData.append("LastName", this.state.valueLastName);
        formData.append("Email", this.state.valueEmail);
        formData.append("Nif", this.state.valueNif);
        formData.append("Code", this.state.valueCode)
        formData.append("AcceptPolicyPrivacy", this.state.checkBBLL);
        this.disableSubmitButton();
        this.showLoader();
        axios.post('registerparticipation', formData, {
            withCredentials: true,
            headers: { Accept: "multipart/form-data", 'X-XSRF-TOKEN': this.cookies.get("XSRF-TOKEN") }
        }).then(response => {
            this.hideLoader();
            this.enableSubmitButton();
            const registerResult = response.data;
            if (registerResult.result) {
                this.nextStep(registerResult);
            }
            else{
                this.nextErrorHandler(registerResult);
            }
        }).catch((e) => { console.log(e); this.hideLoader(); this.enableSubmitButton(); this.handlerError() });
    }

    nextStep(registerResult) {
        setTimeout(() => {
            this.redirections[registerResult.resultKey](registerResult);
        }, 500);
    }

    nextErrorHandler(registerResult) {
        setTimeout(() => {
            this.errorHandlers[registerResult.resultKey](registerResult);
        }, 500);
    }

    handlerError() {
        this.setState({renderModal: <MyModal title="Error" content="Ha ocurrido algún error inesperado. Vuelve a intentarlo más tarde"/>});
    }

    handleInvalidCodeError(registerResult){
        this.setState({renderModal: <MyModal title="Error" content={registerResult.errorMessage} buttonText="Entendido" onExitedCallBack={() => this.setState({renderModal: null})}/>});
    }

    handleRegistrationFieldsErrors(registerResult) {
        const errorMessage = registerResult.errors.map(error => error.errorMessage).join('. ');
        this.setState({renderModal: <MyModal title="Error" content={errorMessage} buttonText="Entendido" onExitedCallBack={() => this.setState({renderModal: null})}/>});
    }

    redirectThanks(registerResult) {
        this.props.setPromoState(ParticipationRegisterStatus.ParticipationDataRegistered);
        this.props.setResultObject(registerResult);
        this.setState({ useNavigation: <Navigate to={`/${process.env.REACT_APP_HOME}/gracias`} replace={true} /> });
    }

    privacyConditions(e) {

        e.preventDefault();
        this.setState({ tiltleModal: "Protección de datos" });
        this.setState({ contentModal: "FOOD DELIVERY BRANDS, S.A. tratará el dato de tu correo electrónico con la finalidad de poder participar en la promoción, en base a tu consentimiento y al cumplimiento de los términos y condiciones de la promoción. Tus datos no serán comunicados a terceros salvo obligación legal o proveedores. Los usuarios tienen el derecho de acceder a sus datos, rectificarlos o suprimirlos, entre otros derechos." });
        if (document.getElementById("Subscribe_Modal_noti")) {
            var myModal = new bootstrap.Modal(document.getElementById("Subscribe_Modal_noti"), {});
            myModal.show();
        }
    }

    showLoader = () => {
        this.setState({ loader: <MyModal title="" content="Registrando" buttonText="" animate={false}/>});
    }

    hideLoader = () => {
        this.setState({ loader: null });
    }

    renderBBLL() {

        return <span className="no-margin policy-privacy-span">
            Acepto las<a target="_blank" onClick={() => {
                window.gtag('event', 'Página_Registro', { 'eventCategory': 'Checks_participacion', 'eventLabel': 'Proteccion_datos' });
            }} href={config.PlayAndPizza.Urls.PolicyPrivacyUrl} rel="noopener noreferrer"> condiciones de privacidad
            </a> y las < a target="_blank" onClick={() => {
                window.gtag('event', 'Página_Registro', { 'eventCategory': 'Checks_participacion', 'eventLabel': 'Condiciones_privacidad' });
            }} rel="noopener noreferrer" href={config.PlayAndPizza.Urls.LegalBasesUrl} > bases legales</a >.
        </span >
    }

    render() {
        return (
            <div className='play_background' id='play_background'>
                {this.state.useNavigation}
                <Navbar image={config.PlayAndPizza.HeaderIconlUrl} />
                <HeaderImage image={config.PlayAndPizza.HeaderImageUrl}/>
                {this.state.loader}
                <div className="mx-auto">
                    <form className='needs-validation' noValidate id="subscribe-form">
                        <div className="col-12">
                            <div className="row mx-auto">
                                <div className=" col-12">
                                    <h3 className='play_text_Intro'>{config.PlayAndPizza.Intro.Title}</h3>
                                </div>
                                <div className="col-12 play_tiltle_video">
                                    {config.PlayAndPizza.Intro.Detail ? <p>{config.PlayAndPizza.Intro.Detail}</p> : null}
                                </div>
                            </div>
                            <div className="row ">
                                <div className=" col-12 mx-auto  form-group play_group">
                                    {RenderInput("Name", "valueName", "Nombre", 100, "name", this)}
                                    {RenderInput("LastName", "valueLastName", "Apellidos", 100, "lastName", this)}
                                    {RenderInput("Nif", "valueNif", "DNI", 100, "dni", this)}
                                    {RenderInput("Email", "valueEmail", "Email", 100, "email", this)}
                                    {RenderInput("Code", "valueCode", "Código", 20, "", this)}
                                    <div className='col-12 col-md-10  mx-auto '>
                                        <ReCAPTCHA className='play_Captcha' ref={this.recaptchaRef}
                                            sitekey={this.state.gSiteKey}
                                            onChange={() => { this.SubmmitReCaptcha(); }} />
                                        {this.state.act_retch === false ? <div className=" play_invalid">
                                            <p className='play_captcha_warning'>  <span className="material-icons play_icon">warning</span>
                                                Debes aceptar para continuar</p>
                                        </div> : null}
                                    </div>
                                    <div className='col-md-12' >
                                        <div className='form-text-container'>
                                            <p className="play_bbll_pre" >Consulta la <a target="_blank" rel="noopener noreferrer" className="play_bbll" onClick={(e) => { this.privacyConditions(e) }} >información básica sobre protección de datos</a></p>
                                        </div>
                                    </div>
                                    {RenderCheckBox("flexCheckDefault", "checkBBLL", this.renderBBLL(), this, "Bases_legales")}
                                    <div className='row ' >
                                        <div className='col text-center'>
                                            <button 
                                                onClick={(e) => {
                                                    window.gtag('event', 'Participacion', { 'eventCategory': 'Participacion', 'eventLabel': 'Boton_Participar' });
                                                    this.registerParticipate(e)
                                                }}
                                                id="participar_subscribe"
                                                disabled={this.state.participateButtonDisabled}
                                                className="btn play_button">{config.PlayAndPizza.Intro.Action}</button>
                                        </div>
                                    </div>
                                </div>
                                <div className='row mx-auto '>
                                    {this.state.elementCaptcha}
                                </div>
                            </div>
                        </div>
                        {this.state.renderModal}
                    </form>
                </div>
                <Modal id={"Subscribe_Modal_noti"} content={this.state.contentModal} tiltle={this.state.tiltleModal} />
                <section className="section--primary--alt bg-image bg-image-2 video-player-section">
                    <Video 
                        videoSrc={config.PlayAndPizza.Section.Video.VideoSrc}
                        tiltle={config.PlayAndPizza.Section.Video.Tiltle}
                        text={config.PlayAndPizza.Section.Video.Text}
                        btn={config.PlayAndPizza.Section.Video.Btn}
                        redirect={this.moreInfoRef}
                        ct="Video_trailer_UnLioDeMillones" btn_ga="Ver_mas" video_ga="Play_video" />
                    <br></br>
                    <br></br>
                    <br></br>
                </section>
            </div >
        );
    }
}

RegisterParticipation.propTypes = {
    setResultObject: PropTypes.func.isRequired,
    setPromoState: PropTypes.func.isRequired
}

const mapStateToProps = state => {
    return {
        promoState: state.promoState
    };
};
export default connect(mapStateToProps, { setResultObject, setPromoState })(RegisterParticipation);