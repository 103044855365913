import React from 'react';
import { connect } from 'react-redux';
import Loader from '../components/Vodafone/Loading';

class Modal extends React.Component {

    state = {
        scale: false
    };

    componentDidMount() {
        this.resize();
    }

    resize() {
        if (window.innerWidth <= 760) {
            this.setState({ scale: true });
        }
        else {
            this.setState({ scale: false });
        }
    }


    render() {

        let btn = this.props.actions ? this.props.actions : <button type="submit" onClick={(e) => { e.preventDefault() }} id="participar_subscribe" data-bs-dismiss="modal" aria-label="Close" className={`btn participate-button play_button `} >
            Entendido
        </button>
        let icon = this.props.icon ? this.props.icon : this.props.icon_MY ? <Loader show={true} position={true} /> : null;

        return (
            <div className="modal fade" id={this.props.id ? this.props.id : "Subscribe_Modal"} data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog  modal-dialog-centered modal-xs">
                    <div className="modal-content">
                        <div className="modal-header modal_border play_modal">
                            <div className="row justify-content-md-center">
                                <div className="col-12 offset-2 offset-md-11 col-md-12 play_content_tiltle">
                                    <h5 className="modal-title" id="staticBackdropLabel">{this.props.tiltle}</h5>
                                </div>
                            </div>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body modal_border">
                            <div className="row justify-content-md-center">
                                <div className="offset-1 offset-md-0  col-md-8 col-10 play_content_modal">

                                    {icon}
                                    {this.props.content}
                                </div>
                            </div>
                        </div>
                        <div className="row mx-auto" >
                            <div className="col-12 play_content_modal text-center">
                                {btn}
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        );
    }
}

const mapStateToProps = state => {
    return {

    };
};

export default connect(mapStateToProps, {})(Modal);


