import React from 'react';
import { connect } from 'react-redux';
import '../../styles/cookieconsent.css';
import config from '../../configuration.json';

class Cookies extends React.Component {

    state = {
    };

    componentDidMount() {

        var cc = window.initCookieConsent();
        var parts = window.location.hostname.split('.');
        var subdomainName = parts.shift();
        var domainName = parts.join('.');

        if (!cc.allowedCategory('analytics')) {
            cc.eraseCookies(['_ga', '_gid', '_gat'], '/', window.location.hostname);
            cc.eraseCookies(['_ga', '_gid', '_gat'], '/', domainName);
        };

        cc.run({
            current_lang: 'es',
            autoclear_cookies: true,                   // default: false
            //theme_css: '@Configuration["PromotionConfig:Urls:CookiesConsentCssUrl"]',  // 🚨 replace with a valid path
            theme_css: './cookieconsent.css',  // 🚨 replace with a valid path
            page_scripts: true,                        // default: false

            // mode: 'opt-in'                          // default: 'opt-in'; value: 'opt-in' or 'opt-out'
            // delay: 0,                               // default: 0
            // auto_language: '',                      // default: null; could also be 'browser' or 'document'
            // autorun: true,                          // default: true
            force_consent: false,                   // default: false
            // hide_from_bots: false,                  // default: false
            remove_cookie_tables: true,             // default: false
            cookie_name: 'cc_cookie',               // default: 'cc_cookie'
            cookie_expiration: 182,                 // default: 182 (days)
            // cookie_necessary_only_expiration: 182   // default: disabled
            // cookie_domain: location.hostname,       // default: current domain
            // cookie_path: '/',                       // default: root
            // cookie_same_site: 'Lax',                // default: 'Lax'
            // use_rfc_cookie: false,                  // default: false
            revision: 1,                            // default: 0

            onFirstAction: function (user_preferences, cookie) {
                // callback triggered only once on the first accept/reject action
            },

            onAccept: function (cookie) {
                // console.log('entro');
                // callback triggered on the first accept/reject action, and after each page load
            },

            onChange: function (cookie, changed_categories) {
                if (!cc.allowedCategory('analytics')) {
                    cc.eraseCookies(['_ga', '_gid', '_gat'], '/', window.location.hostname);
                    cc.eraseCookies(['_ga', '_gid', '_gat'], '/', domainName);
                };
            },
    
           gui_options: {
                consent_modal: {
                    layout: 'bar',               // box/cloud/bar
                    position: 'bottom center',     // bottom/middle/top + left/right/center
                    transition: 'slide',           // zoom/slide
                    swap_buttons: false            // enable to invert buttons
                },
                settings_modal: {
                    layout: 'box',                 // box/bar
                    // position: 'left',           // left/right
                    transition: 'slide'            // zoom/slide
                }
            },
            languages: {
                'es': {
                    consent_modal: {
                        title: 'Estamos usando cookies ',
                        description: `{{revision_message}}<br/>Utilizamos cookies propias y de terceros para analizar el uso del sitio web, facilitar la navegación a través del mismo, y proporcionarte una mejor experiencia de usuario. Consulta nuestra <a target="_blank" style="font-weight:bold" href="${config.PlayAndPizza.Urls.CookiesPolicyPdfUrl}">Política de cookies</a>. `,
                        primary_btn: {
                            text: 'Aceptar y seguir navegando',
                            role: 'accept_all'              // 'accept_selected' or 'accept_all'
                        },
                        secondary_btn: {
                            text: 'Editar la configuración',
                            role: 'settings'        // 'settings' or 'accept_necessary'
                        
                        },
                        revision_message: 'Estimado usuario, ¡la política de cookies ha cambiado desde la última vez que nos visitaste!'
                    },
                    settings_modal: {
                        title: 'Configuración de Cookies',
                        save_settings_btn: 'Guardar configuración',
                        accept_all_btn: 'Aceptar todo y seguir navegando',
                        reject_all_btn: '.',
                        close_btn_label: 'Cerrar',
                        cookie_table_headers: [
                            { col1: 'Nombre' },
                            { col2: 'Expiracion' },
                            { col3: 'Descripcion' }
                        ],
                        blocks: [
                            {
                                title: 'Valoramos tu privacidad',
                                description: `Cuando visita un sitio web, este puede almacenar o recuperar información en su navegador, principalmente en forma de cookies. Esta información puede ser sobre usted, sus preferencias o su dispositivo y se utiliza principalmente para lograr que el sitio funcione como se espera. La información generalmente no lo identifica en forma directa, pero puede brindarle una experiencia web más personalizada. Como respetamos su privacidad, puede optar por excluir algunos tipos de cookies. Puede hacer clic en los diferentes encabezados de categoría para obtener más información y cambiar nuestra configuración predeterminada. Sin embargo, si bloquea algunos tipos de cookies, su experiencia de uso en el sitio se puede ver afectada y también los servicios que podemos ofrecerle. <a target="_blank" href="${config.PlayAndPizza.Urls.CookiesPolicyPdfUrl}">Más información</a>.`
                            },
                            {
                                title: 'Cookies Técnicas o necesarias',
                                description: 'Estas cookies son necesarias para que el sitio web funcione y no se pueden desactivar en nuestros sistemas. Se encargan de aspectos técnicos como identificar perfiles o sesiones y gestionar preferencias de herramientas en el uso, interacción y localización del acceso a los contenidos. Puede configurar su navegador para bloquear o alertar sobre estas cookies, pero algunas áreas del sitio no funcionarán. Estas cookies no almacenan ninguna información de identificación personal. ',
                                toggle: {
                                    value: 'necessary',
                                    enabled: true,
                                    readonly: true
                                },
                                cookie_table: [             // list of all expected cookies
                                    {
                                        col1: 'cc_cookie',
                                        col2: '2 años',
                                        col3: 'Guarda la información de configuración de cookies.'
                                    },
                                    {
                                        col1: '.AspNetCore.Antiforgery.XXXXXXXXXXX',
                                        col2: '2 día',
                                        col3: 'Token de seguridad para evitar ataques de falsificación de solicitud entre sitios web. XXXXXXXXXXX es una cadena de texto variable generada aleatoriamente.',
                                    }
                                ]          // cookie categories with readonly=true are all treated as "necessary cookies"
                            },
                            {
                                title: 'Cookies analíticas',
                                description: 'Estas cookies recogen información relativa al uso del Sitio Web, como la permanencia en el mismo y los contenidos visitados, para comprender cómo interactúan los usuarios con el Sitio Web y así mejorar aspectos de la navegación. Toda la información que recogen estas cookies es agregada y, por lo tanto, es anónima. Si no se permite utilizar estas cookies, no sabremos cuándo visitó nuestro sitio y no podremos evaluar si funcionó correctamente.',
                                toggle: {
                                    value: 'analytics',     // your cookie category
                                    enabled: false,
                                    readonly: false
                                },
                                cookie_table: [             // list of all expected cookies
                                    {
                                        col1: '_ga',
                                        col2: '2 años',
                                        col3: 'Esta cookie está asociada a Google Analytics y se utiliza para distinguir a los usuarios únicos mediante la asignación de un número generado aleatoriamente como identificador de cliente.Se incluye en cada solicitud de página de un sitio y se utiliza para calcular los datos de visitantes, sesiones y campañas de los informes analíticos del sitio'
                                    },
                                    {
                                        col1: '_gid',
                                        col2: '1 día',
                                        col3: 'Esta cookie está asociada a Google Analytics y almacena y actualiza un valor único para cada web visitada.',
                                    },
                                    {
                                        col1: '_gat',
                                        col2: '10 minutos',
                                        col3: 'Esta cookie está asociada a Google Analytics y se utiliza para reducir la tasa de solicitud - limitando la recopilación de datos en sitios de alto tráfico.Expira a los 10 minutos.',
                                    }
                                ]
                            }
                        ]
                    }
                }
            }
        });
    }

    render() {
        return (
          <div></div> 
        );
    }
}

const mapStateToProps = state => {
    return {

    };
};

export default connect(mapStateToProps, {})(Cookies);


